<template>
  <div id="home">
    
    <div class="fixed-top">
      <nav class="navbar navbar-expand-lg navbar-light bg-white px-8">
        <a class="navbar-brand" href="#">
          <img
            src="/assets/img/invoicebull.svg"
            alt="Invoice bull Logo"
            style="max-width: 160px; height: auto;"
          >
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="true"
          aria-label="Toggle navigation"
          @click="toggleMobileMenu"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item " :class="{ active: activeSection === 'home' }">
              <a class="nav-link" @click="scrollToSection('home')">Home</a>
            </li>
            <li class="nav-item" :class="{ active: activeSection === 'aboutUs' }">
              <a class="nav-link" @click="scrollToSection('aboutUs')">About</a>
            </li>
            <li class="nav-item" :class="{ active: activeSection === 'features' }">
              <a class="nav-link" @click="scrollToSection('features')">Features</a>
            </li>
            <li class="nav-item" :class="{ active: activeSection === 'testimonials' }">
              <a class="nav-link" @click="scrollToSection('testimonials')">Testimonials</a>
            </li>
            <li class="nav-item" :class="{ active: activeSection === 'blog' }">
              <a class="nav-link" @click="scrollToSection('blog')">Blog</a>
            </li>
            <li class="nav-item">
              <router-link to="login" class="nav-link">
                Login
              </router-link>
            </li>
          </ul>
        </div>
  
        <!-- // mobile menu //  -->
        <div class="mobilemenu" :style="dynamicstyle" >
          <ul class="navbar-nav ml-auto">
            <li class="nav-item " :class="{ active: activeSection === 'home' }">
              <a class="nav-link" @click="scrollToSection('home')">Home</a>
            </li>
            <li class="nav-item" :class="{ active: activeSection === 'aboutUs' }">
              <a class="nav-link" @click="scrollToSection('aboutUs')">About</a>
            </li>
            <li class="nav-item" :class="{ active: activeSection === 'features' }">
              <a class="nav-link" @click="scrollToSection('features')">Features</a>
            </li>
            <li class="nav-item" :class="{ active: activeSection === 'testimonials' }">
              <a class="nav-link" @click="scrollToSection('testimonials')">Testimonials</a>
            </li>
            <li class="nav-item" :class="{ active: activeSection === 'blog' }">
              <a class="nav-link" @click="scrollToSection('blog')">Blog</a>
            </li>
            <li class="nav-item">
              <router-link to="login" class="nav-link">
                Login
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
  
    </div>
    <div style="padding:10px"></div>

    <br>
    <div class="bg-white">
      <div class="bannerclss">/
       <!-- <div class="container banner_img"> -->
        <!-- <img src="/assets/img/banner_img2.jpg"  alt="..." /> -->
          <!-- <h1>Simplifying Your Financial World</h1>
          <p>Create Invoices and Estimates</p>
          <a class="btn btn-primary learnmore-btn-light" @click="scrollToSection('aboutUs')">Learn More</a> -->
        <!-- </div>  -->
      </div>
    </div>
 

    <div class="about-section" id="aboutUs">
      <div class="container">
        <h2>About invoice Bull</h2>
        <div class="divider-padding"></div>
        <!-- <div class="about-content"> -->
          
          <div class="col justify-content">
            
            <p>

              In the dynamic world of small businesses, time is money. That's why Invoice Bull, the free invoice maker, is here to streamline your invoicing process and help you get paid faster. With its user-friendly interface and robust feature set, Invoice Bull empowers you to create professional invoices, track payments, and generate reports with ease.
              Whether you're a budding entrepreneur or a seasoned business owner, Invoice Bull eliminates the hassle of invoicing, allowing you to focus on what matters most – running your business. Here's why Invoice Bull is the perfect invoicing solution for your small business:
              

            </p>
            
            
          </div>
         
          <!-- <ul class="checklist">
            <li>Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate velit</li>
            <li>Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
          </ul> -->
        <!-- </div> -->
        <a href="#" class="button learnmore-btn learnbtn-hover-lt" @click="scrollToSection('features')">Learn More</a>
      </div>
    </div>

    <div class="features" id="features">
      <div class="container">
        <h2 class="text-center">Features</h2>
        <div class="divider-padding"></div>
        <div class="row">
          <div class="col-md-4 feature">
             <img src="/assets/img/feature_img1.png" alt="Feature 1">
             <div class="features-subtitle">
              <h3>Feature 1</h3>
              <p>Description of feature 1.</p>
            </div>
          </div>
          <div class="col-md-4 feature">
            <img  src="/assets/img/feature_img2.png" alt="Feature 2">
            <div class="features-subtitle">
            <h3>Feature 2</h3>
            <p>Description of feature 2.</p>
            </div>
          </div>
          <div class="col-md-4 feature">
            <img src="/assets/img/feature_img3.png" alt="Feature 3">
            <div class="features-subtitle">
            <h3>Feature 3</h3>
            <p>Description of feature 3.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <div class="row">
          <div class="col-md-6">
            
              <ul>
                  <li>Create and Send Professional Invoices:</li>
                  <li>Manage Customer Information</li>
                  <li>Generate Reports</li>
                  <li>Track Invoice Status</li>
                  <li>Convert Estimates to Invoices</li>
                  <li>Add Multiple Currencies</li>
                  <li>Customize Invoice Templates</li>
              </ul>
          </div>
          <div class="col-md-6">
              <ul>
                  <li>Add Multiple Currencies</li>
                  <li>Customize Invoice Templates</li>
                  <li>Import Data</li>
                  <li>Track Expenses</li>
                  <li>Sync Across Devices</li>
                  <li>Send Estimates through Mail and WhatsApp</li>
              </ul>
          </div>
      </div>
  </div>

<div class="testimonials" id="testimonials">
  <h2>Testimonial</h2>
  <div class="divider-padding"></div>
     <!-- Carousel wrapper -->
<div id="carouselMultiItemExample" class="carousel slide carousel-dark text-center" data-mdb-ride="carousel">
 
  <!-- Inner -->
  <div class="carousel-inner py-4">
    <!-- Single item -->
    <div class="carousel-item active">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <img class="rounded-circle shadow-1-strong mb-4"
              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp" alt="avatar"
              style="width: 150px;" />
            <h5 class="mb-3">Anna Deynah</h5>
            <div v-if="showReadMore1">
              {{ truncatedText(testimonial1, 3, 100) }}
              <span v-if="showReadMore1 && shouldShowReadMore(testimonial1, 3, 100)"
                    @click="toggleReadMore(1)" class="read-more-btn">Read More</span>
            </div>
            <div v-else>
              {{ testimonial1 }}
              <span @click="toggleReadMore(1)" class="read-more-btn">Read Less</span>
            </div>
            <ul class="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
            </ul>
          </div>

          <div class="col-lg-4 d-none d-lg-block">
            <img class="rounded-circle shadow-1-strong mb-4"
              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp" alt="avatar"
              style="width: 150px;" />
            <h5 class="mb-3">John Doe</h5>
            
            <div v-if="showReadMore1">
              {{ truncatedText(testimonial2, 3, 100) }}
              <span v-if="showReadMore2 && shouldShowReadMore(testimonial2, 3, 100)"
                    @click="toggleReadMore(1)" class="read-more-btn">Read More</span>
            </div>
            <div v-else>
              {{ testimonial2 }}
              <span @click="toggleReadMore(1)" class="read-more-btn">Read Less</span>
            </div>
            <ul class="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li>
                <i class="fas fa-star-half-alt fa-sm"></i>
              </li>
            </ul>
          </div>

          <div class="col-lg-4 d-none d-lg-block">
            <img class="rounded-circle shadow-1-strong mb-4"
              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp" alt="avatar"
              style="width: 150px;" />
            <h5 class="mb-3">Maria Kate</h5>
           
            <div v-if="showReadMore1">
              {{ truncatedText(testimonial3, 3, 100) }}
              <span v-if="showReadMore3 && shouldShowReadMore(testimonial3, 3, 100)"
                    @click="toggleReadMore(1)" class="read-more-btn">Read More</span>
            </div>
            <div v-else>
              {{ testimonial3 }}
              <span @click="toggleReadMore(1)" class="read-more-btn">Read Less</span>
            </div>
            <ul class="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="far fa-star fa-sm"></i></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Single item -->
   

  
  </div>
  <!-- Inner -->
</div>
<!-- Carousel wrapper -->

</div>

<div class="features blog-section" id="blog">
      <div class="container">
        <h2 class="text-center primary-color-text">Our latest Blog</h2>
        <div class="divider-padding"></div>
        <div class="row">
          <div class="col-md-4 feature">
            <div class="blog-container"><img src="https://cdn.dribbble.com/users/1991539/screenshots/9106913/media/1700d8dbfb241934105c2d44e567143e.png?resize=400x0" class="round-image" alt="Feature 1"></div>
             <div class="features-subtitle">
              <h3>Feature 1</h3>
              <p>Description of feature 1.</p>
            </div>
          </div>
          <div class="col-md-4 feature">
            <div class="blog-container"><img src="https://assets.materialup.com/uploads/f3b97b6a-d2cb-4268-9a46-b80bce193bbb/preview.png" class="round-image" alt="Feature 2"></div>
            <div class="features-subtitle">
            <h3>Feature 2</h3>
            <p>Description of feature 2.</p>
            </div>
          </div>
          <div class="col-md-4 feature">
            <div class="blog-container"><img src="https://cdn.dribbble.com/users/1991539/screenshots/9106913/media/1700d8dbfb241934105c2d44e567143e.png?resize=400x0" class="round-image" alt="Feature 1"></div>
            <div class="features-subtitle">
            <h3>Feature 3</h3>
            <p>Description of feature 3.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footerdiv">
      <div class="container text-center" style="padding-top:20px">
        <h2>Partner with us</h2>
        <div class="row justify-content-center mt-4">
            <div class="col-md-4 mb-3">
                <h5>Mailing Address</h5>
                <p>123 Anywhere St., Any City, State, Country 12345</p>
            </div>
            <div class="col-md-4 mb-3">
                <h5>Email Address</h5>
                <p>hello@reallygreatsite.com</p>
            </div>
            <div class="col-md-4 mb-3">
                <h5>Phone Number</h5>
                <p>(123) 456 7890</p>
            </div>
        </div>
  
      
    </div>   
    <footer class="site-footer footer-style-main">
      <input type="hidden" id="authtoken" />
      <div class="text-center">
        {{ $t('general.powered_by') }}
        <a href="www.invoicebull.com" target="_blank">Invoice Bull</a>
      </div>
    </footer>

    </div>

  
   

    
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeSection: 'home', // Initialize with the default section (e.g., 'home'),
      mobileMenuOpen: false,
      menuclass:'displaymenunone',
      display:'none',

      showReadMore1: true,
      testimonial1: `Invoice Bull has been a lifesaver for my small business. And it’s free to use your good to go free invoice maker for small business. I used to spend hours every week creating and sending invoices, but now I can do it in just a few minutes. The app is incredibly easy to use, and it has all the features I need to manage my invoices and payments.`,

      showReadMore2: true,
      testimonial2: `As a small business owner, I'm always on the lookout for cost-effective solutions to streamline my operations. Invoice Bull is free Invoice Maker and it has been a fantastic discovery, offering a comprehensive invoicing tool without breaking the bank.
I was initially hesitant about using a free invoicing app, fearing limitations or hidden charges. However, Invoice bull has exceeded my expectations, providing a robust set of features that rivals even paid invoicing software
`,

      showReadMore3: true,
      testimonial3: `As a small business owner, I've always struggled with the tedious and time-consuming process of creating and sending invoices. I've tried various invoicing software solutions, but they often came with hefty price tags or limited features. That's when I discovered Invoice Bull, a free invoice maker that has completely transformed my invoicing experience.
Invoice Bull is incredibly easy to use, even for someone like me who isn't tech-savvy. I was able to create my first professional-looking invoice within minutes of downloading the app. The app's intuitive interface and step-by-step guide make it effortless to navigate and manage my invoices.
`,


     
    };
  },
  watch: {
    $route: 'onRouteChange'
  },
  computed:{
    dynamicstyle(){
      return {
        display:this.display
        // Add more styles as needed
      };
    }
  },
  methods: {
    toggleMobileMenu() {
      // this.mobileMenuOpen = !this.mobileMenuOpen;
      if(this.display == 'none'){
        this.display = 'block';
      }else{
        this.display = 'none';
      }
    },
    scrollToSection(sectionId) {
      const section = document.querySelector(`#${sectionId}`);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        this.activeSection = sectionId;
      }
    },
    onRouteChange() {
      this.activeSection = this.$route.name; // Set activeSection based on route name
    },

    toggleReadMore(testimonialNumber) {
      this[`showReadMore${testimonialNumber}`] = !this[`showReadMore${testimonialNumber}`];
    },
    truncatedText(text, lineCount, maxLength) {
      const lines = text.split('\n').slice(0, lineCount);
      const truncated = lines.join('\n');
      return text.length > maxLength ? truncated.slice(0, maxLength) + '...' : truncated;
    },
    shouldShowReadMore(text, lineCount, maxLength) {
      return text.length > maxLength || text.split('\n').length > lineCount;
    },

    
  }
};
</script>

<style scoped>
.read-more-btn {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
</style>