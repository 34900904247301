var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "home" } }, [
    _c("div", { staticClass: "fixed-top" }, [
      _c(
        "nav",
        { staticClass: "navbar navbar-expand-lg navbar-light bg-white px-8" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "navbar-toggler",
              attrs: {
                type: "button",
                "data-toggle": "collapse",
                "data-target": "#navbarTogglerDemo02",
                "aria-controls": "navbarTogglerDemo02",
                "aria-expanded": "true",
                "aria-label": "Toggle navigation",
              },
              on: { click: _vm.toggleMobileMenu },
            },
            [_c("span", { staticClass: "navbar-toggler-icon" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "navbarTogglerDemo02" },
            },
            [
              _c("ul", { staticClass: "navbar-nav ml-auto" }, [
                _c(
                  "li",
                  {
                    staticClass: "nav-item ",
                    class: { active: _vm.activeSection === "home" },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        on: {
                          click: function ($event) {
                            return _vm.scrollToSection("home")
                          },
                        },
                      },
                      [_vm._v("Home")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "nav-item",
                    class: { active: _vm.activeSection === "aboutUs" },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        on: {
                          click: function ($event) {
                            return _vm.scrollToSection("aboutUs")
                          },
                        },
                      },
                      [_vm._v("About")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "nav-item",
                    class: { active: _vm.activeSection === "features" },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        on: {
                          click: function ($event) {
                            return _vm.scrollToSection("features")
                          },
                        },
                      },
                      [_vm._v("Features")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "nav-item",
                    class: { active: _vm.activeSection === "testimonials" },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        on: {
                          click: function ($event) {
                            return _vm.scrollToSection("testimonials")
                          },
                        },
                      },
                      [_vm._v("Testimonials")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "nav-item",
                    class: { active: _vm.activeSection === "blog" },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        on: {
                          click: function ($event) {
                            return _vm.scrollToSection("blog")
                          },
                        },
                      },
                      [_vm._v("Blog")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "nav-link", attrs: { to: "login" } },
                      [_vm._v("\n                Login\n              ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mobilemenu", style: _vm.dynamicstyle }, [
            _c("ul", { staticClass: "navbar-nav ml-auto" }, [
              _c(
                "li",
                {
                  staticClass: "nav-item ",
                  class: { active: _vm.activeSection === "home" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      on: {
                        click: function ($event) {
                          return _vm.scrollToSection("home")
                        },
                      },
                    },
                    [_vm._v("Home")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item",
                  class: { active: _vm.activeSection === "aboutUs" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      on: {
                        click: function ($event) {
                          return _vm.scrollToSection("aboutUs")
                        },
                      },
                    },
                    [_vm._v("About")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item",
                  class: { active: _vm.activeSection === "features" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      on: {
                        click: function ($event) {
                          return _vm.scrollToSection("features")
                        },
                      },
                    },
                    [_vm._v("Features")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item",
                  class: { active: _vm.activeSection === "testimonials" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      on: {
                        click: function ($event) {
                          return _vm.scrollToSection("testimonials")
                        },
                      },
                    },
                    [_vm._v("Testimonials")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item",
                  class: { active: _vm.activeSection === "blog" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      on: {
                        click: function ($event) {
                          return _vm.scrollToSection("blog")
                        },
                      },
                    },
                    [_vm._v("Blog")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "nav-link", attrs: { to: "login" } },
                    [_vm._v("\n                Login\n              ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { padding: "10px" } }),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "about-section", attrs: { id: "aboutUs" } }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", [_vm._v("About invoice Bull")]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-padding" }),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "button learnmore-btn learnbtn-hover-lt",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.scrollToSection("features")
              },
            },
          },
          [_vm._v("Learn More")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _vm._m(4),
    _vm._v(" "),
    _c("div", { staticClass: "testimonials", attrs: { id: "testimonials" } }, [
      _c("h2", [_vm._v("Testimonial")]),
      _vm._v(" "),
      _c("div", { staticClass: "divider-padding" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-dark text-center",
          attrs: {
            id: "carouselMultiItemExample",
            "data-mdb-ride": "carousel",
          },
        },
        [
          _c("div", { staticClass: "carousel-inner py-4" }, [
            _c("div", { staticClass: "carousel-item active" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-4" }, [
                    _c("img", {
                      staticClass: "rounded-circle shadow-1-strong mb-4",
                      staticStyle: { width: "150px" },
                      attrs: {
                        src: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp",
                        alt: "avatar",
                      },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "mb-3" }, [_vm._v("Anna Deynah")]),
                    _vm._v(" "),
                    _vm.showReadMore1
                      ? _c("div", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.truncatedText(_vm.testimonial1, 3, 100)
                              ) +
                              "\n              "
                          ),
                          _vm.showReadMore1 &&
                          _vm.shouldShowReadMore(_vm.testimonial1, 3, 100)
                            ? _c(
                                "span",
                                {
                                  staticClass: "read-more-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleReadMore(1)
                                    },
                                  },
                                },
                                [_vm._v("Read More")]
                              )
                            : _vm._e(),
                        ])
                      : _c("div", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.testimonial1) +
                              "\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "read-more-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.toggleReadMore(1)
                                },
                              },
                            },
                            [_vm._v("Read Less")]
                          ),
                        ]),
                    _vm._v(" "),
                    _vm._m(5),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-4 d-none d-lg-block" }, [
                    _c("img", {
                      staticClass: "rounded-circle shadow-1-strong mb-4",
                      staticStyle: { width: "150px" },
                      attrs: {
                        src: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp",
                        alt: "avatar",
                      },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "mb-3" }, [_vm._v("John Doe")]),
                    _vm._v(" "),
                    _vm.showReadMore1
                      ? _c("div", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.truncatedText(_vm.testimonial2, 3, 100)
                              ) +
                              "\n              "
                          ),
                          _vm.showReadMore2 &&
                          _vm.shouldShowReadMore(_vm.testimonial2, 3, 100)
                            ? _c(
                                "span",
                                {
                                  staticClass: "read-more-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleReadMore(1)
                                    },
                                  },
                                },
                                [_vm._v("Read More")]
                              )
                            : _vm._e(),
                        ])
                      : _c("div", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.testimonial2) +
                              "\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "read-more-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.toggleReadMore(1)
                                },
                              },
                            },
                            [_vm._v("Read Less")]
                          ),
                        ]),
                    _vm._v(" "),
                    _vm._m(6),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-4 d-none d-lg-block" }, [
                    _c("img", {
                      staticClass: "rounded-circle shadow-1-strong mb-4",
                      staticStyle: { width: "150px" },
                      attrs: {
                        src: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp",
                        alt: "avatar",
                      },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "mb-3" }, [_vm._v("Maria Kate")]),
                    _vm._v(" "),
                    _vm.showReadMore1
                      ? _c("div", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.truncatedText(_vm.testimonial3, 3, 100)
                              ) +
                              "\n              "
                          ),
                          _vm.showReadMore3 &&
                          _vm.shouldShowReadMore(_vm.testimonial3, 3, 100)
                            ? _c(
                                "span",
                                {
                                  staticClass: "read-more-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleReadMore(1)
                                    },
                                  },
                                },
                                [_vm._v("Read More")]
                              )
                            : _vm._e(),
                        ])
                      : _c("div", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.testimonial3) +
                              "\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "read-more-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.toggleReadMore(1)
                                },
                              },
                            },
                            [_vm._v("Read Less")]
                          ),
                        ]),
                    _vm._v(" "),
                    _vm._m(7),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm._m(8),
    _vm._v(" "),
    _c("div", { staticClass: "footerdiv" }, [
      _vm._m(9),
      _vm._v(" "),
      _c("footer", { staticClass: "site-footer footer-style-main" }, [
        _c("input", { attrs: { type: "hidden", id: "authtoken" } }),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("general.powered_by")) + "\n        "
          ),
          _c(
            "a",
            { attrs: { href: "www.invoicebull.com", target: "_blank" } },
            [_vm._v("Invoice Bull")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "navbar-brand", attrs: { href: "#" } }, [
      _c("img", {
        staticStyle: { "max-width": "160px", height: "auto" },
        attrs: { src: "/assets/img/invoicebull.svg", alt: "Invoice bull Logo" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg-white" }, [
      _c("div", { staticClass: "bannerclss" }, [_vm._v("/\n       ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col justify-content" }, [
      _c("p", [
        _vm._v(
          "\n\n              In the dynamic world of small businesses, time is money. That's why Invoice Bull, the free invoice maker, is here to streamline your invoicing process and help you get paid faster. With its user-friendly interface and robust feature set, Invoice Bull empowers you to create professional invoices, track payments, and generate reports with ease.\n              Whether you're a budding entrepreneur or a seasoned business owner, Invoice Bull eliminates the hassle of invoicing, allowing you to focus on what matters most – running your business. Here's why Invoice Bull is the perfect invoicing solution for your small business:\n              \n\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "features", attrs: { id: "features" } }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", { staticClass: "text-center" }, [_vm._v("Features")]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-padding" }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4 feature" }, [
            _c("img", {
              attrs: { src: "/assets/img/feature_img1.png", alt: "Feature 1" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "features-subtitle" }, [
              _c("h3", [_vm._v("Feature 1")]),
              _vm._v(" "),
              _c("p", [_vm._v("Description of feature 1.")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4 feature" }, [
            _c("img", {
              attrs: { src: "/assets/img/feature_img2.png", alt: "Feature 2" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "features-subtitle" }, [
              _c("h3", [_vm._v("Feature 2")]),
              _vm._v(" "),
              _c("p", [_vm._v("Description of feature 2.")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4 feature" }, [
            _c("img", {
              attrs: { src: "/assets/img/feature_img3.png", alt: "Feature 3" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "features-subtitle" }, [
              _c("h3", [_vm._v("Feature 3")]),
              _vm._v(" "),
              _c("p", [_vm._v("Description of feature 3.")]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container mt-5" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("ul", [
            _c("li", [_vm._v("Create and Send Professional Invoices:")]),
            _vm._v(" "),
            _c("li", [_vm._v("Manage Customer Information")]),
            _vm._v(" "),
            _c("li", [_vm._v("Generate Reports")]),
            _vm._v(" "),
            _c("li", [_vm._v("Track Invoice Status")]),
            _vm._v(" "),
            _c("li", [_vm._v("Convert Estimates to Invoices")]),
            _vm._v(" "),
            _c("li", [_vm._v("Add Multiple Currencies")]),
            _vm._v(" "),
            _c("li", [_vm._v("Customize Invoice Templates")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("ul", [
            _c("li", [_vm._v("Add Multiple Currencies")]),
            _vm._v(" "),
            _c("li", [_vm._v("Customize Invoice Templates")]),
            _vm._v(" "),
            _c("li", [_vm._v("Import Data")]),
            _vm._v(" "),
            _c("li", [_vm._v("Track Expenses")]),
            _vm._v(" "),
            _c("li", [_vm._v("Sync Across Devices")]),
            _vm._v(" "),
            _c("li", [_vm._v("Send Estimates through Mail and WhatsApp")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      {
        staticClass:
          "list-unstyled d-flex justify-content-center text-warning mb-0",
      },
      [
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
        _vm._v(" "),
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
        _vm._v(" "),
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
        _vm._v(" "),
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
        _vm._v(" "),
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      {
        staticClass:
          "list-unstyled d-flex justify-content-center text-warning mb-0",
      },
      [
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
        _vm._v(" "),
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
        _vm._v(" "),
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
        _vm._v(" "),
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
        _vm._v(" "),
        _c("li", [_c("i", { staticClass: "fas fa-star-half-alt fa-sm" })]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      {
        staticClass:
          "list-unstyled d-flex justify-content-center text-warning mb-0",
      },
      [
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
        _vm._v(" "),
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
        _vm._v(" "),
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
        _vm._v(" "),
        _c("li", [_c("i", { staticClass: "fas fa-star fa-sm" })]),
        _vm._v(" "),
        _c("li", [_c("i", { staticClass: "far fa-star fa-sm" })]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "features blog-section", attrs: { id: "blog" } },
      [
        _c("div", { staticClass: "container" }, [
          _c("h2", { staticClass: "text-center primary-color-text" }, [
            _vm._v("Our latest Blog"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "divider-padding" }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 feature" }, [
              _c("div", { staticClass: "blog-container" }, [
                _c("img", {
                  staticClass: "round-image",
                  attrs: {
                    src: "https://cdn.dribbble.com/users/1991539/screenshots/9106913/media/1700d8dbfb241934105c2d44e567143e.png?resize=400x0",
                    alt: "Feature 1",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "features-subtitle" }, [
                _c("h3", [_vm._v("Feature 1")]),
                _vm._v(" "),
                _c("p", [_vm._v("Description of feature 1.")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4 feature" }, [
              _c("div", { staticClass: "blog-container" }, [
                _c("img", {
                  staticClass: "round-image",
                  attrs: {
                    src: "https://assets.materialup.com/uploads/f3b97b6a-d2cb-4268-9a46-b80bce193bbb/preview.png",
                    alt: "Feature 2",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "features-subtitle" }, [
                _c("h3", [_vm._v("Feature 2")]),
                _vm._v(" "),
                _c("p", [_vm._v("Description of feature 2.")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4 feature" }, [
              _c("div", { staticClass: "blog-container" }, [
                _c("img", {
                  staticClass: "round-image",
                  attrs: {
                    src: "https://cdn.dribbble.com/users/1991539/screenshots/9106913/media/1700d8dbfb241934105c2d44e567143e.png?resize=400x0",
                    alt: "Feature 1",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "features-subtitle" }, [
                _c("h3", [_vm._v("Feature 3")]),
                _vm._v(" "),
                _c("p", [_vm._v("Description of feature 3.")]),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "container text-center",
        staticStyle: { "padding-top": "20px" },
      },
      [
        _c("h2", [_vm._v("Partner with us")]),
        _vm._v(" "),
        _c("div", { staticClass: "row justify-content-center mt-4" }, [
          _c("div", { staticClass: "col-md-4 mb-3" }, [
            _c("h5", [_vm._v("Mailing Address")]),
            _vm._v(" "),
            _c("p", [
              _vm._v("123 Anywhere St., Any City, State, Country 12345"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4 mb-3" }, [
            _c("h5", [_vm._v("Email Address")]),
            _vm._v(" "),
            _c("p", [_vm._v("hello@reallygreatsite.com")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4 mb-3" }, [
            _c("h5", [_vm._v("Phone Number")]),
            _vm._v(" "),
            _c("p", [_vm._v("(123) 456 7890")]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }